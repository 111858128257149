/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

/* set our default themes here*/
.Theme-LWC {
    --portal-left-background: url("https://cdn.mogatrevsupport.com/idp/left_lwcback2.png");
    --portal-loading-img: url("https://cdn.mogatrevsupport.com/idp/loadingdetails.png");
    --portal-left-backcolor: #F2FAFD;
    --portal-right-backcolor: #F2FAFD;
    --portal-body-backcolor: white;
    --portal-right-label-color: #023143;
    --portal-right-dropdown-color: white;
    --portal-right-dropdown-border-color: black;
    --portal-welcome-title-color: #023143;
    --portal-launcher-text-color: #023143;

}

.Theme-ISD {
    --portal-left-background: url("https://cdn.mogatrevsupport.com/idp/leftback.png");
    --portal-loading-img: url("https://cdn.mogatrevsupport.com/idp/loadingdetails.png");
    --portal-left-backcolor: #000048;
    --portal-right-backcolor: #000000;
    --portal-body-backcolor: #000000;
    --portal-right-label-color: white;
    --portal-right-dropdown-color: #444;
    --portal-right-dropdown-border-color: #111111 #0a0a0a black;
    --portal-welcome-title-color: white;
    --portal-launcher-text-color: wheat;
}

.Theme-MRC {
    --portal-left-background: url("https://cdn.mogatrevsupport.com/idp/leftback.png");
    --portal-loading-img: url("https://cdn.mogatrevsupport.com/idp/loadingdetails.png");
    --portal-left-backcolor: #000048;
    --portal-right-backcolor: #000000;
    --portal-body-backcolor: #000000;
    --portal-right-label-color: white;
    --portal-right-dropdown-color: #444;
    --portal-right-dropdown-border-color: #111111 #0a0a0a black;
    --portal-welcome-title-color: white;
    --portal-launcher-text-color: wheat;
}

.Theme-IRH {
    --portal-left-background: url("https://cdn.mogatrevsupport.com/idp/leftback.png");
    --portal-loading-img: url("https://cdn.mogatrevsupport.com/idp/loadingdetails.png");
    --portal-left-backcolor: #000048;
    --portal-right-backcolor: #000000;
    --portal-body-backcolor: #000000;
    --portal-right-label-color: white;
    --portal-right-dropdown-color: #444;
    --portal-right-dropdown-border-color: #111111 #0a0a0a black;
    --portal-welcome-title-color: white;
    --portal-launcher-text-color: wheat;
}

.Theme-NoTheme {
    --portal-left-background: url("https://cdn.mogatrevsupport.com/idp/leftback.png");
    --portal-loading-img: url("https://cdn.mogatrevsupport.com/idp/loadingdetails.png");
    --portal-left-backcolor: #000048;
    --portal-right-backcolor: #000000;
    --portal-body-backcolor: #000000;
    --portal-right-label-color: white;
    --portal-right-dropdown-color: #444;
    --portal-right-dropdown-border-color: #111111 #0a0a0a black;
    --portal-welcome-title-color: white;
    --portal-launcher-text-color: wheat;
}


/*finish setting themes*/
.portal_body {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: var(--portal-body-backcolor);
    
}

.portal_left {
    background-color: var(--portal-left-backcolor);
    height: 100%;
    width: 250px;
    float: left;
    background-image: var(--portal-left-background);
}

.waitingauthimg {
    margin-left: 100px;
    height: 110px;
    width: 210px;
    background-image: url("https://cdn.mogatrevsupport.com/idp/keys.png")
}

.configuringimg {
    margin-left: 100px;
    height: 110px;
    width: 210px;
    background-image: var(--portal-loading-img);
}

.insessionimg {
    margin-left: 100px;
    height: 210px;
    width: 250px;
    background-image: url("https://cdn.mogatrevsupport.com/idp/insession1.jpg")
}

.noaccountimg {
    margin-left: 100px;
    height: 210px;
    width: 210px;
    background-image: url("https://cdn.mogatrevsupport.com/idp/notfound.png")
}

.authenticated_body {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #000000;
}

.authenticatedimg {
    margin-left: 100px;
    height: 402px;
    width: 604px;
    background-image: url("https://cdn.mogatrevsupport.com/idp/authenticated.png")
}

.authenticatingimg {
    margin-left: 100px;
    height: 402px;
    width: 604px;
    background-image: url("https://cdn.mogatrevsupport.com/idp/authenticating.png")
}

.image-container {
    display: flex;
    justify-content: center;
}

.authenticated_text {
    text-align: center;
    color: var(--portal-right-label-color);
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 25px;
}

.portal_right {
    background-color: var(--portal-right-backcolor);
    height: 100%;
    width: 400px;
    margin-left: 250px;
    padding-top: 30px;
}

.env_form_group {
    margin-bottom: 30px;
}

.profile_label {
    color: var(--portal-right-label-color);
    margin-left: 20px;
}

.env_label {
    color: var(--portal-right-label-color);
    margin-left: 20px;
}

.mode_label {
    color: var(--portal-right-label-color);
    margin-left: 20px;
}

.login_button {
    margin-right: 25px;
    margin-left: 100px;
    width: 200px;
}

.info_button {
    margin-right: 25px;
    margin-left: 300px;
    width: 50px;
}

.portal_container {
    background-color: #000000;
    position: absolute;
    top: 0;
    height: 400px;
    background-color: #000048;
    width: 650px;
    /*right: calc((100vw / 2) - 200px);*/
    left: 0;
    background-repeat: no-repeat;
}

.waiting_text {
    text-align: center;
    color: var(--portal-right-label-color);
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 5px;
    margin-left: 25px;
}

.session_text {
    text-align: center;
    color: var(--portal-right-label-color);
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 20px;
}

.sessionprogress_text {
    text-align: center;
    color: var(--portal-right-label-color);
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 135px;
}

.welcome_title {
    text-align: center;
    color: var(--portal-welcome-title-color);
    font-size: 18px;
    margin-top: 250px;
    margin-bottom: 5px;
}

.launcher_text {
    text-align: center;
    color: var(--portal-launcher-text-color);
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 5px;
}

.loading_title {
    text-align: center;
    color: var(--portal-right-label-color);
}

.register_img {
    height: 50px;
    margin: 15px;
}

.register_clinic_label {
    color: white;
}

.error_img {
    height: 225px;
    margin-left: 290px;
}

.error_retry_button {
    margin-left: 150px;
}

.error_message {
    text-align: center;
    color: var(--portal-right-label-color);
    
}

.error_body {
    background-color: var(--portal-right-backcolor);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.error_container {
    position: absolute;
    top: calc((100vh / 2) - 200px);
    height: 400px;
    background-color: var(--portal-right-backcolor);
    width: 400px;
    right: calc((100vw / 2) - 200px);
}

.error_title {
    text-align: center;
    margin-top: 130px;
    color: var(--portal-right-label-color);
}

.register_body {
    background-color: var(--portal-right-backcolor);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.register_clinic_endpoint_button {
}

.register_clinic_endpoint_name_group {
    margin: 15px 50px 20px 15px;
}

.register_container {
    position: absolute;
    top: calc((100vh / 2) - 200px);
    height: 400px;
    background-color: var(--portal-right-backcolor);
    width: 400px;
    right: calc((100vw / 2) - 200px);
}

.register_title {
    text-align: center;
    color: white;
}

.register_clinic_code_group {
    margin: 25px 50px 20px 15px;
}

.register_error_title {
    text-align: center;
    margin-top: 130px;
    color: white;
}

.registering_title {
    text-align: center;
    margin-top: 130px;
    color: white;
}

.dropdown-dark {
    background-image: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
    background-image: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
    background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
    webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
    background: var(--portal-right-dropdown-color);
    border-color: var(--portal-right-dropdown-border-color);
    color: #5687cb;
    width: 90%;
    margin-left: 20px;
}

    .dropdown-dark:before {
        border-bottom-color: #aaa;
    }

    .dropdown-dark:after {
        border-top-color: #aaa;
    }

    .dropdown-dark .dropdown-select {
        color: #aaa;
        text-shadow: 0 1px black;
        background: #444; /* Fallback for IE 8 */
    }

        .dropdown-dark .dropdown-select:focus {
            color: #ccc;
        }

        .dropdown-dark .dropdown-select > option {
            background: #444;
            text-shadow: 0 1px rgba(0, 0, 0, 0.4);
        }

.extended_container {
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.extended_grid{

}
.portal_body {
}


.k-grid .k-table-row tr{
    height: 25px  !important;
}
.profile_link {
    color: lightblue !important;
    text-decoration: none !important;
}

    .profile_link:hover {
        color: blue !important;
        text-decoration: underline !important;
        outline: 0 !important;
        cursor: pointer !important;
    }
.k-grid-table-wrap{
    height: calc(100vh - 135px) !important;
}
/* set zoom level for screens under 1024px */
@media only screen and (max-width : 1024px) {
    /* Styles */
    .extended_grid {
        zoom: 90%;
    }
    .extended_grid {
        height: calc(100vh + 40px) !important;
    }
}